import "./ResultShortLink.css"

import { ReactComponent as CopyLogo} from '../../../../../img/copy_link.svg';
import { ReactComponent as Cross} from '../../../../../img/cross.svg';
import { ReactComponent as Check} from '../../../../../img/check.svg';
import IconButton from '../../../../Modules/Button/IconButton';
import { ReactComponent as Logo} from '../../../../../img/short.svg';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import Snackbar from "../../../../Modules/Snackbar/Snackbar";

const MESSAGE_TIMEOUT = 5000

const CopyButton = ({ url, setSnackbar }) => {
    const [button, setButton] = useState({ fill: 'default', icon: <CopyLogo width="28px" height="28px"/>})
  
    const copyUrl = () => {
      const result = copy(url);
      
      if (result) {
        setButton({ fill: 'positive', icon: <Check width="28px" height="28px"/>})
        setSnackbar(<Snackbar message="Cокращенная ссылка скопирована!" type="success" setSnackbar={setSnackbar}/>)
      } else {
        setButton({ fill: 'negative', icon: <Cross width="28px" height="28px"/>})
        setSnackbar(<Snackbar message="Ошибка копирования!" type="error" setSnackbar={setSnackbar}/>)
      }
    }
  
    useEffect(() => {
      if (button.fill === 'default') return
  
      const timeout = setTimeout(() => {
        setButton({ fill: 'default', icon: <CopyLogo width="28px" height="28px"/>})
      }, MESSAGE_TIMEOUT)
  
      return ()  =>  clearTimeout(timeout)
    }, [button])
  
    return <IconButton 
        fill={button.fill}
        style={{ marginLeft: '10px'}}
        onClick={copyUrl}
    >
        {button.icon}
    </IconButton>
}
    

export default function ResultShortLink({ url, setSnackbar }) {
    return <div className='ResultBodyLink' key={url}>
        <a 
            rel="noreferrer" 
            target='_blank' 
            href={`https://${url}`}
            className='ResultBodyShortLink'
        >
            <div className='ResultBodyShortLinkBackground'/>

            <div className='ResultBodyShortLinkLogo'>
                <Logo width="16px" height="16px" />
            </div>

            <p className='ResultBodyShort'>{url} </p>
        </a>

        <CopyButton url={url} setSnackbar={setSnackbar}/>
</div>
}