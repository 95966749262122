import "./IconButton.css";

export default function IconButton({ children, onClick, fill = 'default', style = {}, size = 24, disabled }) {
    return <div 
        disabled={disabled}
        className="IconButtonColor" 
        onClick={!disabled ? onClick : undefined}
        style={{ background: `var(--${fill}-icon-button)`, width: size, height: size, ...style}}
    >
        <div className="IconButtonColorBackground" />
        {children}
    </div> 
}