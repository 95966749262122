import "./Button.css"

const COLORS = {
    default: 'var(--default_button',
    positive: 'var(--positive_button',
    negative: 'var(--negative_button'
}

export default function Button({ children, onClick, fill = 'default', style = {}, disabled, href, target }) {
    const calback = () => {
        if (!disabled) {
            if (onClick) onClick()

            if (href) {
                let a = document.createElement('a')
                a.href = href
                a.target = target
                document.body.append(a)
                a.click()
            }
        }
    }

    return <button 
        disabled={disabled}
        style={{ background: COLORS[fill], ...style }}
        className="DefaultButton"
        onClick={calback}
    >
        {children}
    </button>
}