import './Menu.css'

import Body from './Body/Body'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import { useState } from 'react'

export default function Menu() {
    const [snackbar, setSnackbar] = useState(null)

    return <div className="App">
        <div className="Menu">
            <Header />
            <Body setSnackbar={setSnackbar}/>
            <Footer />
        </div>
        {snackbar}
    </div>
}