import "./Message.css";
import { createRef } from "react";
import { ReactComponent as Logo} from '../../../img/cross.svg';

export default function Message({ message, setMessage, style }) {
    const element = createRef()

    const close = () => {
        console.log(element.current.style.animation = 'DeleteMessage .3s forwards');

        setTimeout(() => setMessage(null), 300)
    }

    if (!message) return null;

    return <div className="Message" ref={element} style={{...style}}>
        <p>{message.text}</p>
        <div className="IconButton" onClick={close}>
            <Logo width="16px" height="16px" />
        </div>
    </div>
}