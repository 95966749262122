import "./Password.css"
import { useEffect, useState } from "react";
import Button from "../Modules/Button/Button";
import Message from "../Modules/Message/Message";
const [code, err] = window.location.hash.replace('#', "").split('&')

export default function Password() {
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState(null)

    const go = async () => {
        window.location.href = `/${code}/${password}`
    }

    useEffect(() => {
        if (err) {
            console.log(err);
            setMessage({ type: 'error', text: 'Неверный пароль' })
        }
    }, [])

    return <div className="App">
        <div className="Menu" style={{ alignItems: 'center', justifyContent: 'center'}}>
            <div className="PasswordMenu">
                <p className="HeaderText">Ссылка защищена паролем</p>

                <input 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="Input" 
                    placeholder="Введите пароль"
                    type="password"
                />

                <Message 
                    message={message} 
                    setMessage={setMessage} 
                    style={{ marginBottom: '0', marginTop: '10px', borderRadius: '11px'}}
                />

                <Button 
                    style={{ width: '100%', marginTop: '10px'}} 
                    disabled={!password.length}
                    onClick={go}
                >
                    Перейти
                </Button>
            </div>
        </div>
    </div>
}