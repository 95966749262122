import { useEffect } from "react";
import "./Snackbar.css"

export default function Snackbar({ message, type, setSnackbar, style }) {
    const deleteSnackbar = () => {
        let element = document.querySelector('.SnackBarItem')
        element.style.animation = 'deleteSnackbar 0.3s forwards';
        
        setTimeout(() => {
            setSnackbar(null)
        }, 400)
    }

    useEffect(() => {
        let timeot = setTimeout(() => {
            deleteSnackbar()
        }, 6000)

        return () => {
            clearTimeout(timeot)
        }
    })

    return <div className='SnackBarItem'onClick={deleteSnackbar} style={{
        backgroundColor: `var(--snackbar-${type})`, 
        border: `1px solid var(--snackbar-${type}-border)`,
        color: `var(--${type}-text)`,
        ...style
    }}>
        <p>{message}</p>
    </div>
}