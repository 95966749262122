import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Banned from './components/Banned/Banned';
import Password from './components/Password/Password';
import Menu from './components/Menu/Menu';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="banned" element={<Banned />} />
        <Route path="password" element={<Password />} />
        <Route path="*" element={<Menu />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
