import './ShareLink.css'

import { ReactComponent as Vk} from'../../../../../img/vk.svg'
import { ReactComponent as Ok} from'../../../../../img/ok.svg'
import { ReactComponent as Telegram} from'../../../../../img/telegram.svg'
import { ReactComponent as Twitter} from'../../../../../img/twitter-x.svg'
import { ReactComponent as Whatsapp} from'../../../../../img/whatsapp.svg'
import { ReactComponent as Viber} from'../../../../../img/viber.svg'

const VK = 'https://vk.com/share.php?url='
const OK = "https://connect.ok.ru/offer?url="
const TELEGRAM = 'https://t.me/share/url?url='
const TWITTER = 'https://twitter.com/intent/tweet?url='
const WHATSAPP = 'https://api.whatsapp.com/send?text='
const VIBER = 'viber://forward?text='

export default function ShareLink({ url, bottom }) {
    if (bottom && window.innerWidth > 600) return null
    if (!bottom && window.innerWidth < 600) return null


    return <div className='ShareMenuWindow'>
        <p className="TextHeader">Поделиться ссылкой</p>
        <div className='ShareMenu'>
        <a 
            href={VK + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ backgroundColor: 'var(--vk-color' }}
        >
            <div className='ShareButton'>
                <Vk width="24px" height="24px"/>
            </div>
        </a>

        <a 
            href={OK + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ marginLeft: '10px', backgroundColor: 'var(--ok-color'}}
        >
            <div className='ShareButton'>
                <Ok width="24px" height="24px"/>
            </div>
        </a>

        <a 
            href={TELEGRAM + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ marginLeft: '10px', backgroundColor: 'var(--tg-color'}}
        >
            <div className='ShareButton'>
                <Telegram width="24px" height="24px"/>
            </div>
        </a>

        <a 
            href={TWITTER + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ marginLeft: '10px', backgroundColor: 'var(--twitter-color'}}
        >
            <div className='ShareButton' >
                <Twitter width="24px" height="24px"/>
            </div>
        </a>

        <a 
            href={WHATSAPP + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ marginLeft: '10px', backgroundColor: 'var(--whatsapp-color'}}
        >
            <div className='ShareButton'>
                <Whatsapp width="24px" height="24px"/>
            </div>
        </a>

        <a 
            href={VIBER + encodeURIComponent("https://" + url)} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='ShareButtonA'
            style={{ marginLeft: '10px', backgroundColor: 'var(--viber-color'}}
        >
            <div className='ShareButton'>
                <Viber width="24px" height="24px"/>
            </div>
        </a>
    </div>
    </div>
}