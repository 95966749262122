import './ResultLink.css'
import './StarsFon.css'
import { memo, useEffect, useRef, useState } from 'react'
import QRCodeStyling from "qr-code-styling";
import copy from 'copy-to-clipboard';
import ShareLink from './ShareLink';
import ResultShortLink from './ResultShortLink';
import Snackbar from '../../../../Modules/Snackbar/Snackbar';

const qrCode = new QRCodeStyling({
  width: 500,
  height: 500,
  type: 'canvas',
  margin: 20,
  dotsOptions: {
    color: "#4267b2",
    type: "extra-rounded",
    gradient: {type: 'linear', rotation: 62, colorStops: [{offset: 0, color: '#de3843'}, {offset: 1, color: '#e66019'}]},
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  },
  cornersSquareOptions: {
      type: "extra-rounded",
  }
});

const Stars = () =>  <>
  <div id='stars'></div>
  <div id='stars2'></div>
  <div id='stars3'></div>
</>

const ResultLink = memo(({ result, setSnackbar }) => {
  const [url, setUrl] = useState(null);
  const ref = useRef(null);
  
  useEffect(() => {
    if (!result) return
    setUrl(`${window.process.BASE_URL}/${result.code}`)
    qrCode.append(ref.current);
  }, [result]);

  useEffect(() => {
    if (!url) return 
    qrCode.update({ data: url });
  }, [url])

  if (!result) return <div className="ResultBody">
    <Stars />

    <div className='ResulUrltMenuInfo'>
        <span>Здесь появится ваша короткая ссылка</span>
    </div>
  </div>

  return <><div className="ResultBody">
    <Stars />

    <div className='ResultBodyLeftMenu'>
      <ResultShortLink url={url} setSnackbar={setSnackbar}/>
      <ShareLink url={url}/>
    </div>

    <div className='ResultBodyRightMenu'>
      <div className='ResultBodyCode'>
          <div ref={ref} className='QrCode' />
      </div>
    </div>

    <ShareLink url={url} bottom={true}/>

  </div>
  <div 
      className='OrigResultLink' 
      onClick={() => {
        const result = copy(url)

        if (result) {
          setSnackbar(<Snackbar message="Ссылка скопирована!" type="success" setSnackbar={setSnackbar}/>)
        } else {
          setSnackbar(<Snackbar message="Ошибка копирования!" type="error" setSnackbar={setSnackbar}/>)
        }
      }}
    >
      {result.url}
    </div>
  </>
})

export default ResultLink