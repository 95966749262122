import "./Banned.css"
import { ReactComponent as Virus} from "../../img/virus-infected.svg"
import Win1Token from "../../img/1win_coin.png"
import Button from "../Modules/Button/Button"
import request from '../../utils/request'

export default function Banned () {
    const log = () => {
        request('stats', "POST", { type: '1win coin' })
    }

    return <div className="App">
        <div className="Menu" style={{ alignItems: 'center', justifyContent: 'center'}}>
            <div className="BannedMenu">
                <Virus />
                <h1>Переход по ссылке заблокирован!</h1>
                <p>Сокращенная Ссылка ведет на подозрительный сайт, переход по ней может быть опасен! Если вы считаете что это ошибка свяжитесь со мной по <a href="https://хор-енотов.рф">ссылке</a></p>

                <div className="Reward1WinToken">
                <div className="Reward1WinTokenInfo">
                    <h1>1win Token
                        Зарабатывайте токены до листинга на бирже!
                    </h1>

                    <Button 
                        style={{ marginTop: '10px'}} 
                        href="http://t.me/token1win_bot/start?startapp=refId448148292"
                        target="_blank"
                        onClick={log}
                    >
                        Играть
                    </Button>
                </div>

                <div>

                </div>
                <img src={Win1Token} alt="promo"/>
            </div>
            </div>
        </div>
    </div>
}